<template>
  <page-content>

    <a-space align="start">
      <a-card title="团队管理">
        <common-table
            ref="table"
            path="web/machine/team"
            :columns="columns"
        >

          <template slot="search">
            <a-button ghost type="primary" @click="add">新增</a-button>
          </template>

          <template slot="operation" slot-scope="{record}">
            <action-edit @click="edit(record)"></action-edit>
            <action-delete @click="remove(record)"></action-delete>
            <a-button size="small" @click="clickSub(record)">成员管理</a-button>
          </template>

        </common-table>
      </a-card>


      <a-card :title="selectedTeam.name + '-成员管理'" v-if="selectedTeam.id">
        <common-table
            ref="childrenTable"
            path="web/machine/team-member/tree"
            :columns="memberColumns"
            :pageable="false"
            :query-params="{teamId:this.selectedTeam.id}"
        >

          <template slot="search">
            <a-button ghost type="primary" @click="addMember">新增</a-button>
          </template>

          <template slot="operation" slot-scope="{record}">
            <action-edit @click="editMember(record)"></action-edit>
            <action-delete @click="removeMember(record)"></action-delete>
          </template>

        </common-table>
      </a-card>

    </a-space>

    <TeamEdit ref="teamEdit" @success="getList"></TeamEdit>
    <TeamMemberEdit ref="teamMemberEdit" @success="getList"></TeamMemberEdit>
  </page-content>
</template>

<script>

import TeamEdit from './TeamEdit'
import TeamMemberEdit from "./TeamMemberEdit"

export default {
  components: {
    TeamEdit, TeamMemberEdit
  },
  data() {
    return {
      selectedTeam: {},
    }
  },

  computed: {
    columns() {
      return [
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '创建时间',
          dataIndex: 'createdAt',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    },
    memberColumns() {
      return [
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '手机号',
          dataIndex: 'phone'
        },
        {
          title: '创建时间',
          dataIndex: 'createdAt',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    },
  },
  methods: {
    add() {
      this.$refs.teamEdit.show()
    },
    addMember() {
      this.$refs.teamMemberEdit.show({teamId: this.selectedTeam.id})
    },
    edit(record) {
      this.$refs.teamEdit.show(record)
    },
    editMember(record) {
      this.$refs.teamMemberEdit.show(record)
    },
    remove(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/machine/team/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
            if (that.selectedTeam.id == record.id) {
              that.selectedTeam = {}
            }
          })
        }
      })
    },
    removeMember(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/machine/team-member/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
            if (that.selectedTeam.id == record.id) {
              that.selectedTeam = {}
            }
          })
        }
      })
    },
    getList() {
      this.$refs.table.getData()
      this.$refs.childrenTable.getData()
    },
    clickSub(record) {
      this.selectedTeam = record
      this.$refs.childrenTable.getData()
    }
  }
}
</script>

<style scoped>
.table-row {
  display: flex;

}
</style>
